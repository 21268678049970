// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "foundation-sites"
import $ from 'jquery';
import select2 from 'select2';
import flatpickr from "flatpickr";
import 'select2/dist/css/select2.css';
import 'flatpickr/dist/flatpickr.css';
import "@fortawesome/fontawesome-free/js/all";
require("stylesheets/application")
import "controllers"

$(document).on('turbolinks:load', function() {
  $(document).foundation()
});

$(document).ready(function() {
  $("select.select2-input").on('select2:select', function () {
    let event = new Event('change'); // fire a native event
    this.dispatchEvent(event);
  });
});

$(document).on('turbolinks:load', function() {
  $('input#select-all').click(function() {
      var checkboxes = document.getElementsByName('shift_ids[]');
      for(var i=0, n=checkboxes.length;i<n;i++) {
        checkboxes[i].checked = document.getElementById('select-all').checked;
      };
    }
  );
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
