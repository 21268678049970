import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect();

    this.initSelect2Fields();
  }

  initSelect2Fields() {
    this.select2Fields.forEach((el) => {
      if (!(el.classList.contains('select2-hidden-accessible'))) {
        $(el).select2();
        this.setUpChangeEvent($(el));
      }
    });
  }

  // sets a select2 (jQuery) element up to appropriately dispatch 'change' events
  // upon selection
  setUpChangeEvent(elem) {
    elem.on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire native event
      this.dispatchEvent(event);
    });
  }

  get select2Fields() {
    return new Array(...this.element.querySelectorAll("select.select2-input"));
  }
}
